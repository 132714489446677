<template>
  <div>
    <div
      style="
        display: flex;
        justify-content: space-between;
        align-items: flex-end;
      "
      class="mt-2 mb-2"
    >
      <div v-if="ifDisplayCount">Count: {{ count }}</div>
      <div v-if="!ifDisplayCount"></div>
      <div>
        <slot
          :onDelete="onRemoveSelected"
          :onUpdate="updateItems"
          :onAdminUpdate="adminUpdate"
          :onUpdateBuy="onUpdateBuy"
          :onClear="onClear"
          :expand="onExpandAllClick"
          :collapse="onCollapseAllClick"
          :downloadTable="downloadTable"
          :onUpdateAdminIndexStore="onUpdateAdminIndexStore"
        ></slot>
      </div>
    </div>
    <!-- <span v-if="masterDetailflag">* Please expand row to input LM values</span> -->
    <ag-grid-vue
      style="width: 100%; height: 400px"
      class="ag-theme-balham"
      :gridOptions="gridOptions"
      :columnDefs="colDefs.columnDefs"
      :floatingFilter="true"
      :defaultColDef="colDefs.defaultColDef"
      :masterDetail="masterDetailflag"
      :detailCellRendererParams="detailCellRendererParams"
      :detailRowHeight="detailRowHeight"
      rowSelection="multiple"
      :singleClickEdit="true"
      :suppressRowClickSelection="true"
      :enableRangeSelection="true"
      :rowData="data"
      @cellClicked="cellClicked"
      @cellValueChanged="cellValueChanged"
      @columnValueChanged="columnValueChanged"
      :groupSelectsChildren="true"
      @selection-changed="onSelectionChanged"
      @firstDataRendered="autoSizeAll"
      @CellValueChangedEvent="cellEditingStopped"
      :autoGroupColumnDef="autoGroupColumnDef"
      :rowClassRules="rowClassRules"
      :context="context"
      :frameworkComponents="frameworkComponents"
      @rowSelected="rowSelected"
      :groupDefaultExpanded="groupDefaultExpanded"
    ></ag-grid-vue>
    <!-- :getRowHeight="getRowHeight" -->
    <!-- @grid-ready="onGridReady" -->
  </div>
</template>

<script>
import { concat } from "lodash";
import { AgGridVue } from "ag-grid-vue";
import { mapGetters, mapActions, mapMutations } from "vuex";
import { defaultCoreCipherList } from "constants";
import { truncate, truncateSync, constants } from "fs";
import cellRenderCheckBox from "./CellRender/CellRenderCheckBox";
import cellRenderCheckBoxDcTwenty from "./CellRender/CellRenderCheckBoxDcTwenty";
import cellRenderCheckBoxArticleOwnHistory from "./CellRender/CellRenderCheckBoxArticleOwnHistory";
import salesStartDateCellRender from "./CellRender/SalesStartDateCellRender";
import salesEndDateCellRender from "./CellRender/SalesEndDateCellRender";
import seasonStartDate from "./CellRender/seasonStartDate";
import salesStartDateCellRender2 from "./CellRender/SalesStartDateCellRender2";
import salesEndDateCellRender2 from "./CellRender/SalesEndDateCellRender2";
import merchSelectionDropDownCellRender from "./CellRender/MerchSelectionDropDownCellRender";
import merchSelectionTextBoxCellRender from "./CellRender/MerchSelectionTextBoxCellRender";
import StrategySelectCellRenderer from "./CellRender/StrategySelectCellRenderer";
import StrategySelectRefStore2 from "./CellRender/StrategySelectRefStore2";
import StrategySelectAllStoreCellRender from "./CellRender/StrategySelectAllStoreCellRender";
import includeUnRestrictedDCOH from "./CellRender/IncludeUnRestrictedDCOH";
import includeUnRestrictedDCOO from "./CellRender/IncludeUnRestrictedDCOO";
import merchHierarchy from "./CellRender/MerchHierarchy";
import SapDownloadFlag from "./CellRender/SapDownloadFlag";
import NumericEditor from "./NumericCellEditor";
import { debug } from "util";
import Swal from "sweetalert2/dist/sweetalert2.js";
import "sweetalert2/src/sweetalert2.scss";
export default {
  name: "App",
  components: {
    AgGridVue,
    cellRenderCheckBox,
    cellRenderCheckBoxDcTwenty,
    cellRenderCheckBoxArticleOwnHistory,
    salesStartDateCellRender,
    salesEndDateCellRender,
    seasonStartDate,
    salesStartDateCellRender2,
    salesEndDateCellRender2,
    merchSelectionDropDownCellRender,
    //merchSelectionTextBoxCellRender,
    includeUnRestrictedDCOH,
    merchHierarchy,
    SapDownloadFlag,
  },
  props: {
    isTextWrap: {
      type: Boolean,
      default: false,
    },
    dcLevelColor: {
      type: Boolean,
      default: false,
    },
    allowUpdateInDataTable: {
      type: Boolean,
      default: true,
    },
    calSum1: {
      type: Boolean,
      default: false,
    },
    calSum2: {
      type: Boolean,
      default: false,
    },
    defaultCalSum2: {
      type: Boolean,
      default: false,
    },
    defaultErrorForPAAAndTA: {
      type: Boolean,
      default: false,
    },
    deleteActionFlag: {
      type: Boolean,
      default: false,
    },
    // step3ExpandAll: {
    //   type: Boolean,
    //   default: false
    // },
    defaultErrorForSalesBasisOnZero: {
      type: Boolean,
      default: false,
    },
    defaultErrorTALessThanPAA: {
      type: Boolean,
      default: false,
    },
    ifmultiSelect: {
      type: Boolean,
      default: false,
    },
    idForMultiSelect: {
      type: String,
      default: null,
    },
    downloadArticleFlag: {
      type: String,
      default: "Article",
    },
    ifRowClasRule: {
      type: Boolean,
      default: false,
    },
    autoResize: {
      type: Boolean,
      default: true,
    },
    count: {
      type: Number,
      default: 0,
    },
    ifDisplayCount: {
      type: Boolean,
      default: true,
    },
    data: {
      type: Array,
      default: [],
    },
    colDefs: {
      type: Object,
      default: [],
    },
    masterDetailflag: {
      type: Boolean,
      default: false,
    },
    floatingFilter: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      autoGroupColumnDef: null,
      groupDefaultExpanded: null,
      context: null,
      gridOptions: null,
      sideBar: null,
      selectedNodeIndexes: [],
      paginationPageSize: "",
      selectedArticlesPerPage: 50,
      pivotPanelShow: null,
      detailRowHeight: null,
      rowClassRules: null,
      cellClassRules: "sick-days-warning",
      detailCellRendererParams: null,
      sortingOrder: null,
      getRowHeight: null,
    };
  },
  beforeMount() {
    //this.gridApi = this.gridOptions.api;
    let that = this;
    this.gridOptions = {};
    if (this.defaultErrorForPAAAndTA) {
      this.gridOptions.getRowStyle = function (params) {
        if (
          typeof params.data.percentageAlreadyAllocated !== "undefined" ||
          typeof params.data.targetAllocationPercentage !== "undefined"
        ) {
          if (
            params.data.percentageAlreadyAllocated >= 100 ||
            (Number(params.data.targetAllocationPercentage) <=
              params.data.percentageAlreadyAllocated &&
              params.data.targetAllocationPercentage != "")
          ) {
            // params.data.percentageAllocatedAfterMin = 100
            return { background: "#ecd1cc" };
          }
        } else return false;
      };
    }
    // if (this.isTextWrap) {
    //   this.getRowHeight = params => {
    //     return 28 * (Math.floor(params.data.comments.length / 30) + 1);
    //   };
    // }
    // if (this.defaultErrorTALessThanPAA) {
    //   this.gridOptions.getRowStyle = function(params) {
    //     if (typeof params.data.targetAllocationPercentage !== "undefined") {
    //       if () {
    //         // params.data.percentageAllocatedAfterMin = 100
    //         return { background: "#ecd1cc" };
    //       }
    //     } else return false;
    //   };
    // }
    if (this.dcLevelColor) {
      this.gridOptions.getRowStyle = function (params) {
        if (typeof params.data.dcInventoryPostAllocation !== "undefined") {
          if (params.data.dcInventoryPostAllocation < 0) {
            return { background: "#ecd1cc" };
          }
        } else return false;
      };
    }
    if (this.defaultErrorForSalesBasisOnZero) {
      this.rowClassRules = {
        "sick-days-warning": (params) => {
          let flagDialog = false;
          if (params.data.sum1 === 0 || params.data.sum2 === 0) {
            flagDialog = true;
          }
          return flagDialog;
        },
      };
    }
    if (this.ifRowClasRule) {
      this.rowClassRules = {
        disable: (params) => {
          if (typeof params.data.lms !== "undefined") {
            if (params.data.lms.length == 0) return true;
          } else return false;
        },
      };
    }
    this.autoGroupColumnDef = {
      headerName: "STORE ID",
      headerCheckboxSelection: true,
      cellRendererParams: {
        suppressCount: true,
        checkbox: true,
        // headerCheckbox: true,
      },
    };
    if (this.calSum1) {
      this.rowClassRules = {
        "sick-days-warning": (params) => {
          let flagDialog = false;
          if (params.data.sum1 === 0 || params.data.sum2 === 0) {
            flagDialog = true;
          }
          return flagDialog;
        },
      };
    }
    if (this.calSum2) {
      this.rowClassRules = {
        "sick-days-warning3": (params) => {
          if (
            (params.data.salesHistoryStartDate1 &&
              params.data.salesHistoryEndDate1 &&
              params.data.merchHierarchy1 &&
              params.data.weightBasis1 &&
              params.data.salesHistoryStartDate2 &&
              params.data.salesHistoryEndDate2 &&
              params.data.merchHierarchy2 &&
              params.data.weightBasis2 &&
              params.data.targetAllocationPercentage) ||
            (params.data.salesHistoryStartDate1 &&
              params.data.salesHistoryEndDate1 &&
              params.data.merchHierarchy1 &&
              params.data.weightBasis1 &&
              !params.data.salesHistoryStartDate2 &&
              !params.data.salesHistoryEndDate2 &&
              !params.data.merchHierarchy2 &&
              !params.data.weightBasis2 &&
              params.data.targetAllocationPercentage) ||
            (!params.data.salesHistoryStartDate1 &&
              !params.data.salesHistoryEndDate1 &&
              !params.data.merchHierarchy1 &&
              !params.data.weightBasis1 &&
              !params.data.salesHistoryStartDate2 &&
              !params.data.salesHistoryEndDate2 &&
              !params.data.merchHierarchy2 &&
              !params.data.weightBasis2 &&
              !params.data.targetAllocationPercentage)
          ) {
            return false;
          } else {
            return true;
          }
        },
      };
    }
    // if (this.warningForMinGraterThan100) {
    //   this.rowClassRules = {
    //     "sick-days-warning": params => {
    //       // if (typeof params.data.percentageAlreadyAllocated !== "undefined") {
    //         if (params.data.percentageAlreadyAllocated > 100 || params.data.targetAllocationPercentage < params.data.percentageAlreadyAllocated) {
    //           // params.data.percentageAllocatedAfterMin = 100
    //           return true;
    //         }
    //       // } else return false;
    //     }
    //   };
    // }
    this.detailCellRendererParams = {
      detailGridOptions: {
        columnDefs: [
          { field: "lm" },
          {
            field: "min",
            editable: true,
            singleClickEdit: true,
            cellStyle: function (params) {
              return { "background-color": "#F4F6F8" };
            },
          },
          {
            field: "max",
            editable: true,
            singleClickEdit: true,
            cellStyle: function (params) {
              return {
                "background-color": "#F4F6F8",
                "border-left": "1px solid grey",
              };
            },
          },
        ],
        cellClassRules: this.cellClassRules,
        onCellValueChanged(param) {
          if (
            param.colDef.field == "min" &&
            (parseInt(param.data.min) > parseInt(param.data.max) ||
              parseInt(param.data.min) < 0)
          ) {
            param.data.min = null;
          } else if (
            param.colDef.field == "max" &&
            (parseInt(param.data.min) > parseInt(param.data.max) ||
              parseInt(param.data.max < 0))
          ) {
            param.data.max = null;
          }
          param.api.refreshCells();
        },
        onFirstDataRendered(params) {
          params.api.sizeColumnsToFit();
        },
      },
      // forEachDetailGridInfo(callback),
      getDetailRowData: (params) => {
        params.successCallback(params.data.lms);
      },
      template:
        '<div style="height: 100%; padding: 20px; box-sizing: border-box;">' +
        '  <div ref="eDetailGrid" style="width: 50%; height:100%; margin:auto"></div>' +
        "</div>",
    };
    this.detailRowHeight = 200;
    this.context = { componentParent: this };
    this.frameworkComponents = {
      CellRenderCheckBox: cellRenderCheckBox,
      CellRenderCheckBoxDcTwenty: cellRenderCheckBoxDcTwenty,
      CellRenderCheckBoxArticleOwnHistory: cellRenderCheckBoxArticleOwnHistory,
      SalesStartDateCellRender: salesStartDateCellRender,
      SalesEndDateCellRender: salesEndDateCellRender,
      SeasonStartDate: seasonStartDate,
      SalesStartDateCellRender2: salesStartDateCellRender2,
      SalesEndDateCellRender2: salesEndDateCellRender2,
      MerchSelectionDropDownCellRender: merchSelectionDropDownCellRender,
      // MerchSelectionTextBoxCellRender:merchSelectionTextBoxCellRender,
      IncludeUnRestrictedDCOH: includeUnRestrictedDCOH,
      IncludeUnRestrictedDCOO: includeUnRestrictedDCOO,
      StrategySelectCellRenderer: StrategySelectCellRenderer,
      StrategySelectRefStore2: StrategySelectRefStore2,
      StrategySelectAllStoreCellRender: StrategySelectAllStoreCellRender,
      MerchHierarchy: merchHierarchy,
      SapDownloadFlag: SapDownloadFlag,
      numericEditor: NumericEditor,
    };
  },
  mounted() {
    this.gridApi = this.gridOptions.api;
    this.gridColumnApi = this.gridOptions.columnApi;
    // this.$root.$emit("GridAPI", this.gridOptions);
    // if (this.step3ExpandAll) {
    //   this.gridOptions.groupDefaultExpanded = -1; //(expand all functionality)
    // }
  },
  methods: {
    ...mapActions({
      deleteAllocation: "CreateAllocation/deleteAllocation",
      dashboardTable: "Dashboard/dashboardTable",
    }),
    // onGridReady(params) {
    //   const updateData = data => {
    //     data.forEach(function(dataItem) {
    //       var start = Math.floor(Math.random() * (latinText.length / 2));
    //       var end = Math.floor(Math.random() * (latinText.length / 2) + latinText.length / 2);
    //       dataItem.latinText = latinText.substring(start, end);
    //     });
    //   }
    // },
    onExpandAllClick() {
      this.gridApi.forEachNode((node) => (node.expanded = true));
      this.gridApi.onGroupExpandedOrCollapsed();

      this.$gtag.event("Clicked_ExpandAllButton", {
        event_category: "Article Selection Screen",
        event_label: "Click on Expand All Button",
      });
    },
    onCollapseAllClick() {
      this.gridApi.forEachNode((node) => (node.expanded = false));
      this.gridApi.onGroupExpandedOrCollapsed();

      this.$gtag.event("Clicked_CollapseAllButton", {
        event_category: "Article Selection Screen",
        event_label: "Click on Collapse All  Button",
      });
    },
    rowSelected(event) {
      let context = this;
      if (this.ifmultiSelect) {
        if (event.node.selected == true) {
          // let selectedItems = event.api.getSelectedNodes();
          let articleID = event.data.productID;
          this.gridApi.forEachNodeAfterFilterAndSort(function (rowNode, index) {
            if (rowNode.data[context.idForMultiSelect] == articleID) {
              context.gridApi.selectNode(rowNode, true);
            }
          });
        }
      }
    },
    DownloadExcel() {},
    cellEditingStopped() {
      // this.gridApi.refreshCells()
    },
    autoSizeAll() {
      if (this.autoResize) {
        var allColumnIds = [];
        this.gridColumnApi.getAllColumns().forEach(function (column) {
          allColumnIds.push(column.colId);
        });
        this.gridColumnApi.autoSizeColumns(allColumnIds);
      }
    },

    onRemoveSelected(val) {
      if (!this.deleteActionFlag) {
        this.gridOptions.api.setFilterModel(null);
        this.gridOptions.api.onFilterChanged();
        let finalData = {
          existingData: [],
          removedData: [],
          selectedId: val.target.id,
        };

        if (
          this.gridOptions &&
          this.gridOptions.columnDefs &&
          this.gridOptions.columnDefs[1] &&
          this.gridOptions.columnDefs[1].headerName === "LAYOUT MODULE"
        ) {
          this.$gtag &&
            this.$gtag.event("Clicked_StoresExcludeButton", {
              event_category: "Site Exclusion Screen",
              event_label: 'Click on "X" Icon',
            });
        }

        if (
          this.gridOptions &&
          this.gridOptions.columnDefs &&
          this.gridOptions.columnDefs[1] &&
          this.gridOptions.columnDefs[1].headerName === "SELECTION COUNT"
        ) {
          this.$gtag &&
            this.$gtag.event("Clicked_StoresReIncludeButton", {
              event_category: "Site Exclusion Screen",
              event_label: 'Click on "Tickmark" Icon',
            });
        }

        let context = this;
        var selectedData = this.gridApi.getSelectedRows();
        var res = this.gridApi.updateRowData({ remove: selectedData });

        if (
          this.gridOptions &&
          this.gridOptions.columnDefs &&
          this.gridOptions.columnDefs[11] &&
          this.gridOptions.columnDefs[11].headerName === "All Store MIN"
        ) {
          this.$gtag &&
            this.$gtag.event("Clicked_ArticleExclusionDeleteIcon", {
              event_category: "Article Selection Screen",
              event_label: "Click on Delete trash icon",
            });
        } else if (
          this.gridOptions &&
          this.gridOptions.columnDefs &&
          this.gridOptions.columnDefs[2].headerName === "CHAR COLLECTION"
        ) {
          this.$gtag &&
            this.$gtag.event("Clicked_ArticleIxclusionDeleteIcon", {
              event_category: "Article Selection Screen",
              event_label: "Click on Delete trash icon",
            });
        }

        this.gridApi.forEachNodeAfterFilterAndSort(function (rowNode, index) {
          if (typeof rowNode.data !== "undefined")
            finalData.existingData.push(rowNode.data);
        });
        res.remove.forEach((element) => {
          finalData.removedData.push(element.data);
        });
        this.$emit("updatedDataOnRemove", finalData);
      } else {
        this.$dialog
          .confirm("Are you sure you want to delete the allocation project(s)?")
          .then(() => {
            this.$gtag.event("Clicked_DeleteProjectButton", {
              event_category: "Dashboard Screen",
              event_label: "Click on Delete Project Trash Icon",
            });
            this.gridOptions.api.setFilterModel(null);
            this.gridOptions.api.onFilterChanged();
            let finalData = {
              existingData: [],
              removedData: [],
            };
            let context = this;
            var selectedData = this.gridApi.getSelectedRows();
            var res = this.gridApi.updateRowData({ remove: selectedData });
            this.gridApi.forEachNodeAfterFilterAndSort(function (
              rowNode,
              index
            ) {
              if (typeof rowNode.data !== "undefined")
                finalData.existingData.push(rowNode.data);
            });
            res.remove.forEach((element) => {
              finalData.removedData.push(element.data);
            });
            let arr = [];
            finalData.removedData.forEach((obj) => {
              arr.push(obj.allocationid);
            });
            this.deleteAllocation({ allocationIDs: arr }).then((resp) => {
              this.dashboardTable();
            });
          })
          .catch((e) => {
            reject(e);
          });
      }
      this.$emit("removedSuccess", "removed");
    },
    onSelectionChanged(event) {
      if (!this.allowUpdateInDataTable) {
        //  this.selectedItems =  jsonpack.pack( event.api.getSelectedRows())
        // this.$emit("selectedItems",(jsonpack.unpack(this.selectedItems)));
        //  this.selectedItems=null
      } else {
        this.selectedItems = event.api.getSelectedNodes();
        this.$emit("selectedItems", this.selectedItems);
      }
    },
    updateItems(objsToUpdate) {
      var itemsToUpdate = [];
      let context = this;
      let errorFlag = false;
      // this.$emit("onUpdateDcLevelData", true);
      this.gridApi.forEachNodeAfterFilterAndSort(function (rowNode, index) {
        var data = rowNode.data;
        if (rowNode.selected) {
          if (!context.allowUpdateInDataTable) {
            // let pack = Number(data.Pack);
            // let allocationInput = element.value;
            if (objsToUpdate.value % rowNode.data.Pack == 0) {
              // if (context.gridOptions.rowStyle) {
              context.gridOptions.rowStyle = null;
              // context.gridApi.redrawRows({ rowNodes: [rowNode] });
              // }
              data["Allocation"] = objsToUpdate.value;
              data["Total Fill"] =
                objsToUpdate.value + data["Store OO"] + data["Store OH"];
              // element.Allocation = allocationInput.toString()
            } else {
              // rows.push(rowNode);
              context.gridOptions.rowStyle = { background: "#ecd1cc" };
              context.gridApi.redrawRows({ rowNodes: [rowNode] });
              errorFlag = true;
            }
          } else {
            objsToUpdate.forEach((element) => {
              data[element.key] = element.value;
            });
          }
          // itemsToUpdate.push(data);
        }
      });
      if (errorFlag) {
        Swal.fire({
          // title: 'Error!',
          text: "In the highlighted rows, some values are not multiples of pack size.",
          icon: "warning",
          confirmButtonText: "Ok",
        });
      }
      errorFlag = false;
      context = null;
      let objToSend = {
        gridOption: this.gridOptions,
        gridApi: this.gridApi,
      };
      // this.gridApi.updateRowData({ update: itemsToUpdate });
      this.$emit("updatedSuccesfully", objToSend);
      this.$emit("step2MassApply", objToSend);
      this.gridApi.refreshCells();
      this.gridOptions.api.deselectAll();
      this.selectedItems = null;
      objToSend = null;
      objsToUpdate.length = 0;
    },
    adminUpdate(index, refStores) {
      // let selectedRows = this.gridApi.getSelectedNodes();
      if (index && refStores)
        this.gridApi.getSelectedNodes().forEach((ele) => {
          ele.data.indexValueUser = index;
          if (typeof refStores === "string") {
            ele.data.referenceStoreIDUser = refStores;
          } else {
            ele.data.referenceStoreIDUser = ele.data.storeID;
          }
        });
      else if (index && !refStores) {
        this.gridApi.getSelectedNodes().forEach((ele) => {
          ele.data.indexValueUser = index;
        });
      } else {
        this.gridApi.getSelectedNodes().forEach((ele) => {
          if (typeof refStores === "string") {
            ele.data.referenceStoreIDUser = refStores;
          } else {
            ele.data.referenceStoreIDUser = ele.data.storeID;
          }
        });
      }
      this.gridApi.refreshCells();
    },
    onUpdateAdminIndexStore() {
      this.gridApi.refreshCells();
    },
    onClear() {
      this.$emit("onClear", "success");
      this.gridApi.refreshCells();
      this.gridApi.redrawRows();
    },
    onUpdateBuy(objsToUpdate) {
      let objToSend = {
        gridOption: this.gridOptions,
        gridApi: this.gridApi,
      };
      // this.gridApi.updateRowData({ update: itemsToUpdate });
      this.$emit("updatedTotalBuy", objToSend);
      this.gridApi.refreshCells();
    },
    downloadTable(name) {
      var params = {
        fileName: name,
      };
      this.gridApi.exportDataAsExcel(params);

      if (name === "Allocation_Strategy") {
        this.$gtag.event("Clicked_DownloadStrategyScreenTableData", {
          event_category: "Strategy Screen",
          event_label: "Click on Download Template",
        });
      }

      if (name === "Download_DC_Level_Allocation") {
        this.$gtag.event("Clicked_DCLevelAllocationDownloadButton", {
          event_category: "Finalize Allocation Screen",
          event_label: "Click on DC Level allocation Button",
        });
      }
    },
    cellClicked(value) {
      this.$emit("cellClicked", value);
    },
    // sizeToFit() {
    //      var allColumnIds = [];
    //   this.gridColumnApi.getAllColumns().forEach(function(column) {
    //     allColumnIds.push(column.colId);
    //   });
    //   this.gridColumnApi.autoSizeColumns(allColumnIds);
    // },
    cellValueChanged(val) {
      let objToEmit = {
        eventVal: val,
        gridOption: this.gridOptions,
      };
      let row = this.gridApi.getDisplayedRowAtIndex(val.rowIndex);

      this.$emit("cellValueChanged", objToEmit);
      this.gridApi.redrawRows({ rowNodes: [row] });
      this.gridApi.refreshCells();
    },
    columnValueChanged(val) {},
  },
  created() {
    this.selectedItems = null;
    // this.$root.$on('downloadExcel', this.DownloadExcel);
  },
  watch: {
    downloadArticleFlag(val) {},
  },
  destroyed() {
    // this.data = null
    // this.defaultErrorForPAAAndTA = false
    // this.defaultErrorForSalesBasisOnZero = false
    this.calSum2 = false;
    // this.calSum1 = false
  },
};
</script>
<style>
.ag-theme-balham .ag-header-cell-label {
  display: -webkit-flex;
  display: flex;
  height: 100%;
  justify-content: center;
}
.ag-header-group-cell-label {
  justify-content: center !important;
}
.ag-theme-balham .disable {
  background-color: #d8d8d8 !important;
}
.ag-theme-balham .sick-days-warning {
  background-color: #ecd1cc;
}
.ag-theme-balham .sick-days-warning3 {
  background-color: rgba(231, 157, 238, 0.897);
}
.sick-days-warning2 {
  background-color: rgb(68, 61, 55) !important;
}
</style>