import numeral from 'numeral'
import { format } from 'date-fns'

var dateFormatter = function (params) {
  return format(params.value, 'MM/DD/YYYY')
}
var percentageFormatter = function (params) {
  return numeral((params.value/100)).format('0.00%')
}
var dashConditionForPercentageValues = function (params) {
  if (params.value >= 1000000000) {
    return '-'
  } else {
    return numeral((params.value/100)).format('0.00%')
  }
}
var dashConditionForUnitValues = function (params) {
  if (params.value >= 1000000000) {
    return '-'
  }
}
var decimalFormatter = function (params) {
  return numeral(params.value).format('0.00')
}
var decimalFormatterStep4 = function (params) {
  params.value = Number(params.value)
  if (params.value) {
    return numeral(params.value).format('0.00')
  } else {
    return ""
  }
}
var decimalFormatterSalesBasis = function (params) {
  if (params.value == undefined) {
    return 
  } else if (params.value == '-') {
    return '-'
  } else {
    return numeral(params.value).format('0.00')
  }
}
var decimalFormatterStep6withPer = function(params){
  if (!params.value || params.value == "NaN") {
    return "NA"
  }
  else {
    return numeral(params.value/100).format('0.00%')
  }
}
var decimalFormatterStep6 = function (params) {
  if (!params.value || params.value == "NaN") {
    return "NA"
  }
  else {
    return numeral(params.value).format('0.00')
  }
}
var percentageFormatternew = function (params) {
  if (Math.sign(Number(params.value)) == -1) {
    return ""
  } else if (Number(params.value) > 100) {
    return ""
  }
  else if (params.value) {
    return numeral((params.value / 100)).format('0.00%')
  } else {
    return ""
  }
}
var percentageFormatterWeight = function (params){
    if(params.value!=="")
      return numeral((params.value / 100)).format('0.00%')
}
var percentageFormatternew1 = function (params) {
  if (!!params.value)
    return numeral((params.value / 100)).format('0.00%')
  else if (params.value == 0) {
    return numeral(params.value).format('0.00%')
  } else
    return ""
}
var percentageFormatternew2 = function (params) {
  params.value = Number(params.value)
  if (params.value) {
    return numeral((params.value / 100)).format('0.00%')
  }  else {
    return ""
  }
}
var setToNA = function (params) {
  if (!params.value || params.value == "NaN") {
    return "NA"
  } else {
    return numeral(params.value/100).format('0.00%')
  }
}
var ArticlesetToNA = function (params) {
  if (!params.value || params.value == "NaN" || params.value == "") {
    return "NA"
  } else {
    return numeral(params.value/100).format('0.00')
  }
}

var dateFormate = function(params){
  if(!!params.value){
     
    let finalString;

   return params.value.substring(0,2) + "/" + params.value.substring(2,4) + "/" + params.value.substring(4,8) 
    

  }
}
export {
  dashConditionForPercentageValues,
  dashConditionForUnitValues,
  percentageFormatter, 
  decimalFormatter, 
  percentageFormatternew1,
  percentageFormatternew, 
  setToNA,
  percentageFormatternew2,
  decimalFormatterStep6,
  decimalFormatterSalesBasis,
  dateFormatter,
  dateFormate,
  ArticlesetToNA,
  decimalFormatterStep6withPer,
  percentageFormatterWeight,
  decimalFormatterStep4
}
