import Vue from "vue";

export default Vue.extend({
  template: `
        <span>
        <b-form-checkbox
      v-model="status"
      size="sm"
      @change="invokeParentMethod($event)"
    >
    </b-form-checkbox>
    </span>
    `,
    // <input type="checkbox" v-model="status"  @change="invokeParentMethod($event)">
  data: function() {
    return {
      status
    };
  },
  beforeMount() {
    this.status = this.params.data.includeUnrestrictedDCOH
},
mounted() {
},
methods: {
invokeParentMethod(val) {
  this.status = val
  this.params.data.includeUnrestrictedDCOH = val
  let unrestrictedInventory = Number(this.params.data.unrestrictedInventory);
  let availableInventory = Number(this.params.data.availableInventory)
  if(this.status==true){
    availableInventory += unrestrictedInventory
    this.params.data.availableInventory = availableInventory.toString()
  }
  else{
    availableInventory -= unrestrictedInventory
    this.params.data.availableInventory = availableInventory.toString()
  }
  
}
}
});
