import Vue from "vue";
import { mapGetters } from "vuex";
import { format } from 'date-fns'

export default Vue.extend({
  template: `
        <span :key="render">
        <b-form-input type="date" v-model="salesStartDate" :min="minDate" :max="maxDate" @change="invokeParentMethod"/>
        </span>
    `,
  data: function() {
    return {
      salesStartDate: "",
      maxDate: new Date(),
      minDate: null,
      salesMaxDate: null,
      render: false,
    };
  },
  beforeMount() {
    this.salesStartDate = this.params.data.salesHistoryStartDate2
    // this.salesMaxDate = this.params.data.salesHistoryEndDate2;
    this.maxDate = format(this.maxDate, 'YYYY-MM-DD')
    this.minDate = format(this.getFetchMinMaxDateAndFW.minDate, 'YYYY-MM-DD')
  },
  computed: {
    ...mapGetters({
      getFetchMinMaxDateAndFW: 'CreateAllocation/getFetchMinMaxDateAndFW'
    })
  },
  mounted() {},
  // watch: {
  //   maxDate(val) {
  //     this.maxDate = this.params.data.salesHistoryEndDate2;
  //   }
  // },
  methods: {
    invokeParentMethod(val) {
      if (this.params.data.salesHistoryEndDate2 && val > this.params.data.salesHistoryEndDate2) {
        this.params.data.salesHistoryStartDate2 = ""
        this.salesStartDate = ""
        this.render = !this.render
      } else {
        this.params.data.salesHistoryStartDate2 = this.salesStartDate
      }
      this.$root.$emit("step4Flag", false);
    }
  }
});
