import Vue from "vue";
import { mapActions, mapGetters, mapMutations } from 'vuex';

export default Vue.extend({
    template: `
    <div>
      <toggle-button style="padding-top:0.15rem;" :margin="4" :font-size="1" :width="35" :height="15" v-model="flag" :disabled="toggleDisable" @change="invokeParentMethod"/>
    </div>
    `,
    data: function () {
        return {
            flag: false,
            toggleDisable: false
        };
    },
    beforeMount() {
      if (this.params.data.allocationstatus === "SUBMITTED") {
        this.flag = this.params.data.sapReportUploaded
      } else {
        this.toggleDisable = true
        this.flag = this.params.data.sapReportUploaded
      }
    },
    mounted() {
    },
    methods: {
      ...mapActions({
        updateSapReportUploadedFlag: 'CreateAllocation/updateSapReportUploadedFlag',
        dashboardTable: 'Dashboard/dashboardTable'  
      }),
      invokeParentMethod(val) {
        this.updateSapReportUploadedFlag({"allocationID": this.params.data.allocationid, "updateTo": val.value}).then(() => {
          // this.dashboardTable();
        })
      }
    }
});