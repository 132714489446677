import Vue from "vue";
import { mapActions } from "vuex";

export default Vue.extend({
  template: `
        <span>
          <input type="checkbox" 
            ref="isDc09Ref"
            @change="invokeParentMethod($event)">
        </span>
    `,
  data: function() {
    return {
      isDc09: false,
      isDc09Ref: null
    };
  },
  beforeMount() {
    this.dc = this.params.data.is_dc09;
    this.params.data.supplyingSource = this.dc ? 1 : 0;
  },
  mounted() {
    this.isDc09 = this.params.data.is_dc09;
    this.$refs.isDc09Ref.checked = this.isDc09;
  },
  methods: {
    invokeParentMethod(event) {
      event.stopPropagation();

      this.isDc09 = event.target.checked;
      let storeMutationObj = {};
      storeMutationObj.article = this.params.data.article;
      storeMutationObj.supplyingSource = this.isDc09 ? 1 : 0;
      storeMutationObj.is_dc09 = this.isDc09;

      if (this.isDc09) {
        storeMutationObj.is_dc20 = false;
        storeMutationObj.dcTwenty = 0;
      } else {
        storeMutationObj.is_dc20 = this.params.data.is_dc20;
        storeMutationObj.dcTwenty = this.params.data.dcTwenty;
      }

      let element = document.getElementsByClassName("ag-body-viewport ag-layout-normal ag-row-no-animation")[0];
      let previousScrollHeight = element.scrollTop;
      let previousScrollWidth = element.scrollLeft;
      storeMutationObj.callback = () => element.scrollBy(previousScrollWidth, previousScrollHeight);
 
      this.triggerDc09andDc20(storeMutationObj);

      this.$root.$emit("step2Flag", false);
    },
    ...mapActions({
      triggerDc09andDc20: "CreateAllocation/triggerDc09andDc20"
    })
  }
});
