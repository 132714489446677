import Vue from "vue";

export default Vue.extend({
    template: `
        <span>
        <b-form-input type="text" v-model="merchSelection"/>
        </span>
    `,
    data: function () {
        return {
            merchSelection:""
        };
    },
    beforeMount() {
            this.merchSelection = this.params.data.merchSelection
    },
    mounted() {
    },
    methods: {
        invokeParentMethod(val) {
                this.params.data.merchSelection = val
        }
    }
});