import Vue from "vue";

export default Vue.extend({
    template: `
        <span>
        <input type="checkbox" id="articleOwnSalesHistory" v-model="articleOwnSalesHistory"  @change="invokeParentMethod($event)">
        </span>
    `,
    data: function () {
        return {
            articleOwnSalesHistory:false
        };
    },
    beforeMount() {
        if(this.params.data.articleOwnSalesHistory == "Article"){
            this.articleOwnSalesHistory = true
        }
        else{
            this.articleOwnSalesHistory = false
        }
    },
    mounted() {
    },
    methods: {
        invokeParentMethod(event) {
            if(this.articleOwnSalesHistory==true){
                this.params.data.articleOwnSalesHistory = "Article"
            }
            else{
                this.params.data.articleOwnSalesHistory = "Merch_cat"
            }
            //methodFromParent(`Row: ${this.params.node.rowIndex}, Col: ${this.params.colDef.headerName}, eve:event} `)
        }
    }
});