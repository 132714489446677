import Vue from "vue";
import { mapGetters } from "vuex";
import { format } from 'date-fns'

export default Vue.extend({
  template: `
        <span :key="render">
        <b-form-input type="date" v-model="salesStartDate" @change="invokeParentMethod" :min="minDate" :max="maxDate"/>
        </span>
    `,
  data: function () {
    return {
      salesStartDate: null,
      maxDate: new Date(),
      minDate: null,
      salesMaxDate: null,
      render: false
    };
  },
  beforeMount() {
    this.salesStartDate = this.params.data.salesHistoryStartDate1
    this.salesMaxDate = this.params.data.salesHistoryEndDate1
    this.maxDate = format(this.maxDate, 'YYYY-MM-DD')
    this.minDate = format(this.getFetchMinMaxDateAndFW.minDate, 'YYYY-MM-DD')
  },
  mounted() {
  },
  computed: {
    ...mapGetters({
      getFetchMinMaxDateAndFW: 'CreateAllocation/getFetchMinMaxDateAndFW'
    })
  },
  watch: {
  },
  methods: {
    invokeParentMethod(val) {
      if (this.params.data.salesHistoryEndDate1 && val > this.params.data.salesHistoryEndDate1) {
        this.params.data.salesHistoryStartDate1 = ""
        this.salesStartDate = ""
        this.render = !this.render
      } else {
        this.params.data.salesHistoryStartDate1 = this.salesStartDate
      }
      this.$root.$emit("step4Flag", false)
      this.params.api.refreshCells()
    }
  }
});