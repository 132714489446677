import Vue from "vue";
import { mapActions } from "vuex";

export default Vue.extend({
  template: `
  <div>
    <input
      type="checkbox"
      ref="isDc20Ref"
      @change="invokeParentMethod($event)"
      :disabled=isDisabled
    />
</div>
    `,
  data: function () {
    return {
      isDc20: false,
      isDc20Ref: {},
      isDc01: false,
      isDc04: false,
      isDc06: false,
    };
  },
  mounted() {
    this.isDc20 = this.params.data.is_dc20;
    this.isDc01 = this.params.data.is_dc01;
    this.isDc04 = this.params.data.is_dc04;
    this.isDc06 = this.params.data.is_dc06;
    this.$refs.isDc20Ref.checked = this.isDc20 && (this.isDc01 || this.isDc04 || this.isDc06);
  },
  computed: {
    isDisabled() {
      if (this.params.data.is_dc09) {
        return true;
      } else if (!this.params.data.is_dc01 && !this.params.data.is_dc04 && !this.params.data.is_dc06){
        return true;
      }
    }
  },
  methods: {
    invokeParentMethod(event, dc) {
      event.stopPropagation();

      this.isDc20 = event.target.checked;

      let storeMutationObj = {};
      storeMutationObj.article = this.params.data.article;
      storeMutationObj.dcTwenty = this.isDc20 ? 1 : 0;
      storeMutationObj.is_dc20 = this.isDc20;

      if (this.isDc20) {
        storeMutationObj.is_dc09 = false;
        storeMutationObj.supplyingSource = 0;
      } else {
        storeMutationObj.is_dc09 = this.params.data.is_dc09;
        storeMutationObj.supplyingSource = this.params.data.supplyingSource;
      }

      let element = document.getElementsByClassName("ag-body-viewport ag-layout-normal ag-row-no-animation")[0];
      let previousScrollHeight = element.scrollTop;
      let previousScrollWidth = element.scrollLeft;

      storeMutationObj.callback = () => element.scrollBy(previousScrollWidth, previousScrollHeight);

      this.triggerDc09andDc20(storeMutationObj);


      this.$root.$emit("step2Flag", false);
    },
    ...mapActions({
      triggerDc09andDc20: "CreateAllocation/triggerDc09andDc20"
    })
  }
});
