import Vue from "vue";
import { mapGetters } from "vuex";
import { format } from 'date-fns'

export default Vue.extend({
  template: `
        <span :key="render">
        <b-form-input type="date" :min="minDate" :max="maxDate" v-model="salesEndDate" @change="invokeParentMethod"/>
        </span>
    `,
  data: function () {
    return {
      salesEndDate: null,
      maxDate: new Date(),
      minDate: null,
      render: false
    };
  },
  beforeMount() {
    this.salesEndDate = this.params.data.salesHistoryEndDate1
    this.minDate = format(this.getFetchMinMaxDateAndFW.minDate, 'YYYY-MM-DD')
    this.maxDate = format(this.maxDate, 'YYYY-MM-DD')
  },
  mounted() {
  },
  computed: {
    ...mapGetters({
      getFetchMinMaxDateAndFW: 'CreateAllocation/getFetchMinMaxDateAndFW'
    })
  },
  methods: {
    invokeParentMethod(val) {
      if (this.params.data.salesHistoryStartDate1 && val < this.params.data.salesHistoryStartDate1) {
        this.params.data.salesHistoryEndDate1 = ""
        this.salesEndDate = ""
        this.render = !this.render
      } else {
        this.params.data.salesHistoryEndDate1 = this.salesEndDate
      }
      this.$root.$emit("step4Flag", false)
    }
  },
  watch: {
    salesEndDate(val) {
    }
  }
});