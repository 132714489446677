import Vue from "vue";
import { mapGetters } from "vuex";

export default Vue.extend({
  template: `
        <span >
        <b-form-select
        style="width: 35%;"
                    v-model="week"
                    :options="weeks"
                    size="sm"
                    @change="onSelectedWeek"
                    :close-on-select="true"
                    :clear-on-select="false"
                    :preserve-search="true"
                    placeholder="Merch Hierarchy"
                  >
                    <template slot="selection" slot-scope="{ values, search, isOpen }">
                      <span
                        v-if="values.length &amp;&amp; !isOpen"
                      >{{ values.length }} options selected</span>
                    </template>
                  </b-form-select>
                  <b-form-select
                  style="width: 40%;"
                              v-model="year"
                              :options="years"
                              size="sm"
                              @change="onSelectedYear"
                              :close-on-select="true"
                              :clear-on-select="false"
                              :preserve-search="true"
                              placeholder="Merch Hierarchy"
                            >
                              <template slot="selection" slot-scope="{ values, search, isOpen }">
                                <span
                                  v-if="values.length &amp;&amp; !isOpen"
                                >{{ values.length }} options selected</span>
                              </template>
                            </b-form-select>
        </span>
    `,
  data: function () {
    return {
      weeks: [
        "01",
        "02",
        "03",
        "04",
        "05",
        "06",
        "07",
        "08",
        "09",
        "10",
        "11",
        "12",
        "13",
        "14",
        "15",
        "16",
        "17",
        "18",
        "19",
        "20",
        "21",
        "22",
        "23",
        "24",
        "25",
        "26",
        "27",
        "28",
        "29",
        "30",
        "31",
        "32",
        "33",
        "34",
        "35",
        "36",
        "37",
        "38",
        "39",
        "40",
        "41",
        "42",
        "43",
        "44",
        "45",
        "46",
        "47",
        "48",
        "49",
        "50",
        "51",
        "52",
        "53"
      ],
      years: [
        "2015",
        "2016",
        "2017",
        "2018",
        "2019",
        "2020",
        "2021",
        "2022",
        "2023",
        "2024",
        "2025",
        "2026",
        "2027",
        "2028",
        "2029",
        "2030",
        "2031",
        "2032",
        "2033",
        "2034",
        "2035",
        "2036",
        "2037",
        "2038",
        "2039",
        "2040",
        "2041",
        "2042",
        "2043",
        "2044",
        "2045",
        "2046",
        "2047",
        "2048",
        "2049",
        "2050"
      ],
      week: null,
      year: null
    };
  },
  beforeMount() {
    this.week = this.params.data.seasonStartFiscalWeek.slice(0, 2);
    this.year = this.params.data.seasonStartFiscalWeek.slice(2, 7);
    // this.selectedFiscalWeek = a.concat(b);
    // this.seasonStartFiscalWeek = this.params.data.deliveryDate;
  },
  computed: {
    ...mapGetters({
      getFetchMinMaxDateAndFW: 'CreateAllocation/getFetchMinMaxDateAndFW'
    })
  },
  created() {
    let minFW = this.getFetchMinMaxDateAndFW.minFW.slice(4, 6);
    let maxFW = this.getFetchMinMaxDateAndFW.maxFW.slice(4, 6);
    let mixFWY = this.getFetchMinMaxDateAndFW.minFW.slice(0, 4);
    let maxFWY = this.getFetchMinMaxDateAndFW.maxFW.slice(0, 4);
    for (let i = Number(minFW); i <= Number(maxFW); i++) {
      if (i <= 9) {
        this.weeks.push("0" + i);
      } else {
        this.weeks.push(i.toString());
      }
    }
    for (let i = Number(mixFWY); i <= Number(maxFWY); i++) {
      this.years.push(i.toString());
    }
  },
  mounted() { },
  methods: {
    onSelectedWeek(val) {
      if (this.week && this.year) {
        this.params.data.seasonStartFiscalWeek = this.week.concat(this.year);
      }
      this.$root.$emit("step2Flag", false);
    },
    onSelectedYear(val) {
      if (this.week && this.year) {
        this.params.data.seasonStartFiscalWeek = this.week.concat(this.year);
      }
      this.$root.$emit("step2Flag", false);
    }
  }
});
