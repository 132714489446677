import Vue from "vue";

export default Vue.extend({
    template: `
    <b-form-select v-model="merchSelection" :options="merchSelectionOption"
    :close-on-select="true" :clear-on-select="false" :preserve-search="true" placeholder="Merch Selection">
    <template slot="selection" slot-scope="{ values, search, isOpen }"><span v-if="values.length &amp;&amp; !isOpen">{{ values.length }} options selected</span></template>
  </b-form-select>
    `,
    data: function () {
        return {
            merchSelection:"",
            merchSelectionOption:['a','b','c','d','e','f','g']
        };
    },
    beforeMount() {
            this.merchSelection = this.params.data.merchSelection
    },
    mounted() {
    },
    methods: {
        invokeParentMethod(val) {
                this.params.data.merchSelection = val
        }
    }
});