import Vue from "vue";

export default Vue.extend({
  template: `
        <span>
        <b-form-select v-model="value" :options="options" @change="invokeParentMethod"
          :close-on-select="true" :clear-on-select="false" :preserve-search="true" placeholder="Hierarchy">
          <template slot="selection" slot-scope="{ values, search, isOpen }"><span v-if="values.length &amp;&amp; !isOpen">{{ values.length }} options selected</span></template>
        </b-form-select>
        </span>
    `,
  data: function() {
    return {
      value: "",
      options: ["Article", "Merch-Cat", "Category"]
    };
  },
  beforeMount() {
    this.value = this.params.data.merchHierarchy;
  },
  mounted() {},
  methods: {
    invokeParentMethod(val) {
      this.params.data.merchHierarchy = val;
    }
  }
});
