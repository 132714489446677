import Vue from "vue";
import { mapGetters } from "vuex";
import { isEqual } from 'lodash';

export default Vue.extend({
  template: `
  <span>
  <b-form-checkbox
  size="sm"
v-model="status"
@change="invokeParentMethod($event)"
>
</b-form-checkbox>
</span>
    `,
  data: function() {
    return {
      status: false
    };
  },
  beforeMount() {
    if (this.params.data.includeDCOO == 'undefined') {
      this.status = false;
    } else {
      this.status = this.params.data.includeDCOO;
    }
  },
  computed: {
    ...mapGetters({
      getTempJsonData: 'CreateAllocation/getTempJsonData',
      getJsonData: 'CreateAllocation/getJsonData'
    })
  },
  methods: {
    invokeParentMethod(val) {
      this.params.data.includeDCOO = val
      let onOrder = Number(this.params.data.orderedQuantity)
      let unRestrictedInventory = Number(this.params.data.unrestrictedInventory)
      let dcDemand = Number(this.params.data.dcDemand)
      if (val) {
        this.params.data.availableInventory = unRestrictedInventory - dcDemand
        let totalBuy =
        this.params.data.storeTotalOH +
        this.params.data.storeTotalOO +
        this.params.data.inSeasonSales +
        this.params.data.availableInventory;
        this.params.data.totalBuy = totalBuy;
        if (this.params.data.targetAllocationUnits && this.params.data.targetAllocationPercentage) {
          this.params.data.targetAllocationPercentage = "";
          this.params.data.targetAllocationUnits = "";
        }
        this.params.data.percentageAlreadyAllocated =
          ((Number(this.params.data.storeTotalOH) +
            Number(this.params.data.storeTotalOO) +
            Number(this.params.data.inSeasonSales)) /
            totalBuy) *
          100;
      } else {
        this.params.data.availableInventory = unRestrictedInventory + onOrder - dcDemand
        let totalBuy =
        this.params.data.storeTotalOH +
        this.params.data.storeTotalOO +
        this.params.data.inSeasonSales +
        this.params.data.availableInventory;
        this.params.data.totalBuy = totalBuy;
        if (this.params.data.targetAllocationUnits && this.params.data.targetAllocationPercentage) {
          this.params.data.targetAllocationPercentage = "";
          this.params.data.targetAllocationUnits = "";
        }
        this.params.data.percentageAlreadyAllocated =
          ((Number(this.params.data.storeTotalOH) +
            Number(this.params.data.storeTotalOO) +
            Number(this.params.data.inSeasonSales)) /
            totalBuy) *
          100;
      }
      let disableFlag = isEqual(this.getTempJsonData.strategy, this.getJsonData.strategy)
      this.$root.$emit("step4Flag",disableFlag)
      // let unRestrictedInventory = Number(this.params.data.unrestrictedInventory)
      // this.params.data.orderedQuantity = '';
      // this.status=val
      // let orderedQty = Number(this.params.data.orderedQuantity);
      // let availableInventory = Number(this.params.data.availableInventory)

    // if(this.status == true){
    //   let finalNumber = availableInventory+orderedQty;
    //   this.params.data.availableInventory = finalNumber.toString();
    // }
    // else{
    //   let finalNumber = availableInventory-orderedQty;
    //   this.params.data.availableInventory = finalNumber.toString();
    // }
    }
  }
});
